import Vue from 'vue';

// moment
import vueMoment from 'vue-moment';
Vue.use(vueMoment);

// Vuetify
import Vuetify from 'vuetify';
import ja from 'vuetify/es5/locale/ja.js';
import 'vuetify/dist/vuetify.min.css';

//Vue.use(Vuetify);
Vue.use(Vuetify);

const vuetifyOptions = {
    lang: {
        locales: { ja },
        current: 'ja'
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#000000',
                secondary: '#0F68B1',
                tertiary: '#e2edeb',
                quaternary: '#d6e3e2',
                quinary: '#E0E0E0',
                accent: '#EF4E5B',
                error: '#FF8086',
                info: '#80C6FF',
                success: '#86FF80',
                warning: '#FFF980',
                background: '#EDFCFD',
            },
            dark: {
                primary: '#FFFFFF',
                secondary: '#0F68B1',
                tertiary: '#222222',
                quaternary: '#616161',
                quinary: '#E0E0E0',
                accent: '#EF4E5B',
                error: '#FF8086',
                info: '#80C6FF',
                success: '#86FF80',
                warning: '#FFF980',
                background: '#383838',
            },
        },
    },
};

// Element UI
import ElementUI from 'element-ui';
import ElementLang from '../../lang/ja/element-ja';
Vue.use(ElementUI, { locale: ElementLang });

// Dialogs
import Dialog from './components/Dialogs/dialog';
Vue.mixin(Dialog);

import ProceedDialogButton from './components/Shared/ProceedDialogButton';
import CancelDialogButton from './components/Shared/CancelDialogButton';
Vue.component('proceed-dialog-button', ProceedDialogButton);
Vue.component('cancel-dialog-button', CancelDialogButton);

// LoadingScreen
import LoadingScreen from './components/LoadingScreen';
Vue.component('loading-screen', LoadingScreen);

// PaginationTable
import PaginationTable from './components/Tables/PaginationTable';
Vue.component('pagination-table', PaginationTable);

// PageContainer
import PageContainer from './components/PageContainer';
Vue.component('page-container', PageContainer);

// import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import 'element-ui/lib/theme-chalk/index.css';

// Any HTML Element Resize Event
// https://github.com/legomushroom/resize
import anyResizeEvent from './utils/any-resize-event.min';
Vue.use(anyResizeEvent);

// monet
import monet from 'monet';
Vue.mixin(monet);

// import monetPimp from 'monet/dist/monet-pimp.js';
// vue.mixin(monetPimp); // TODO pipm を mixin すると名前が競合する。できれば pimp を使用したい。

// Mixins
import StorageData from './mixins/storage-data-mixin';
import Formatter from './mixins/formatter-mixin';
Vue.mixin(StorageData);
Vue.mixin(Formatter);

// Utilities
import http from './services/http.js';
import userStore from './stores/user-store';
import router from './router';
import event from './utils/event';


const app = new Vue({
    vuetify: new Vuetify(vuetifyOptions),
    data: {
        title: '',
        breadcrumbs: [],
        headerActions: [],
    },
    router,
    created() {
        http.init();
        userStore.init();
        event.init(new Vue);

        http.get('/me')
            .catch(() => {
                console.log('not logged in');
                location.href = '/login.html';
            });

    },
    methods: {
        init() {
            this.title = '';
            this.breadcrumbs = [];
            this.headerActions = [];
        },
        setTitle(title) {
            this.title = title;
        },
        setBreadcrumbs(breadcrumbs) {
            this.breadcrumbs = breadcrumbs;
        },
        setHeaderActions(actions) {
            this.headerActions = actions;
        },
        getTheme() {
            return this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'];
        },
        setBackGroundOfDarkTheme(colorCode) {
            this.$vuetify.theme.themes.dark.background = colorCode;
        },
    },
    render: h => h(require('./components/AppInhouse.vue').default),
}).$mount('#app');
